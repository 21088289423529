<template>
	<div class="page" style="background-color: #3972FD;">
		<!-- 顶部菜单 -->
		<topBar :curIndex="1"></topBar>
		<div class="xc_top_banner_box">
			<div class="banner" v-if="bannerList.length>0">
				<img class="img" :src="bannerList[0].imageUrl" @click="$util.windowOpen(bannerList[0].redirectUrl)"/>
			</div>
			<div class="block40" v-if="bannerList.length<=0"></div>
			<div class="timer_box">
				<div class="timerdiv w1240">
					<div class="text">距离本场结束还有：</div>
					<div class="hover">{{countdown[0]}}{{countdown[1]}}</div>
					<div class="text">小时</div>
					<div class="hover">{{countdown[2]}}{{countdown[3]}}</div>
					<div class="text">分</div>
					<div class="hover">{{countdown[4]}}{{countdown[5]}}</div>
					<div class="text">秒</div>
				</div>
			</div>
		</div>
		<div class="block20"></div>
		<block v-if="isLogin">
		<goodsListC :type="1" :goodsList="dataList" :listType="'tejia'"></goodsListC>
		</block>
		<block v-if="!isLogin">
		<goodsListCn :type="1" :goodsList="dataList" :listType="'tejia'"></goodsListCn>
		</block>
		<div class="block20"></div>
		<div class="pagination_box_white w1240">
		    <el-pagination
		          @current-change="handleCurrentChange"
		          :current-page.sync="currentPage"
		          :page-size="pageSize"
		          layout="prev, pager, next, jumper"
		          :total="total"
				  background>
		    </el-pagination>
		</div>
		<div class="block40"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
import  topBar  from "../components/topBar/topBar.vue";
import  goodsListC from "../components/goods-list/goods-list.vue";
import  goodsListCn from "../components/goods-list/goods-list-nonlogin.vue";
export default {
	components: {
		topBar,
		goodsListC,
		goodsListCn
	},
	data() {
		return {
			uid:"",
			bannerList:[],
			countdown: ["0", "0", "0", "0", "0", "0"], //倒计时
			dataList:[],
			currentPage:1,
			pageSize:10,
			total:0,
		};
	},
	mounted: function() {
		this.getwheelPage(18)
		this.countTime()
		this.getgoodsList()
	},
	computed: {
		...mapState(['userInfo', 'isLogin','bsnName']),
	},
	methods: {
		//获取轮播图
		getwheelPage(type) {
			var _this = this
			return new Promise((resolve, reject) => {
				var params = {}
				params["currentPage"] = 1
				params["pageSize"] = 8
				params["type"] = type
				this.$http.post('AdvertModule/wheel-advert/getPage', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						var records = res.data.records
						if(records.length>0){
							_this.bannerList = records
						}
						resolve(true)
					} else {
						reject(false)
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			})
		},
		//商品列表
		getgoodsList() {
			var _this = this
			var params = {}
			params["currentPage"] = this.currentPage
			params["pageSize"] = this.pageSize
			this.$http.post('medicine/medicineDrugsSpecial/getPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records
					_this.total = res.data.total
					records.forEach((item,index)=>{
						item["finalPrice"] = item.price
					})
					_this.dataList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//跳转页面
		handleCurrentChange(currentPage){
			console.log(currentPage)
			this.currentPage = currentPage
		},
		//倒计时
		countTime() {
		    var that = this
		    var date = new Date()
		    var now = date.getTime()
		    var endDate;
		    endDate = new Date(date.getFullYear()+"/"+(date.getMonth() + 1)+"/"+date.getDate()+" 23:59:59") //设置截止时间
		    var end = endDate.getTime()
		    var leftTime = end - now //时间差
		    var d, h, m, s, ms;
		    if (leftTime >= 0) {
		      d = Math.floor(leftTime / 1000 / 60 / 60 / 24)
		      h = Math.floor(leftTime / 1000 / 60 / 60 % 24)
		      m = Math.floor(leftTime / 1000 / 60 % 60)
		      s = Math.floor(leftTime / 1000 % 60)
		      // ms = Math.floor(leftTime % 1000)
		      // ms = ms < 100 ? "0" + ms : ms
		      s = s < 10 ? "0" + s : s
		      m = m < 10 ? "0" + m : m
		      h = h < 10 ? "0" + h : h
		      var h1 = (h + "").substring(0, 1)
		      var h2 = (h + "").substring(1, 2)
		      var m1 = (m + "").substring(0, 1)
		      var m2 = (m + "").substring(1, 2)
		      var s1 = (s + "").substring(0, 1)
		      var s2 = (s + "").substring(1, 2)
		      var countdown = []
		      countdown.push(h1)
		      countdown.push(h2)
		      countdown.push(m1)
		      countdown.push(m2)
		      countdown.push(s1)
		      countdown.push(s2)
		      //console.log(countdown)
		      that.countdown = countdown
		      // countdown: d + "天" + h + "时" + m + "分" + s + "秒"
		      // 递归每秒调用countTime方法，显示动态时间效果
		      var timerName = setTimeout(function () {
		        clearTimeout(timerName)
		        that.countTime()
		      }, 1000)
		    } else {
		      //console.log('已截止')
		      that.countdown = ["0", "0", "0", "0", "0", "0"]
		    }
		},
		
	}
};
</script>
<style lang="scss" scoped></style>
